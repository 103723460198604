<template>
	<div class="head-wrap">
		<div class="fun-area" :class="navbg?'navbgClass':''">
			<img src="../assets/images/new/logo.png" class="logo">
			<div class="bar">
				<i class="iconfont icon-friend_light" @click="memberClick"></i>
				<i class="iconfont" :class="showNav?'iconguanbi':'icon-daohang'" @click="iconClick"></i>
			</div>
		</div>
		<van-popup v-model="showNav" position="right " :style="{ height: '100%',width:'83%' }" :closeable="true">
			<div class="nav-list">
				<div class="nav-item" v-for="(navItem,navIndex) in navList" :key="navIndex"
					@click="navItemClick(navItem,navIndex)">
					{{navItem.name}}
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	export default {
		props: {
			navbg: {
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				showNav: false,
				navList: [
					{
						name: '全国',
						url: 'https://mall.xiaoyuan.com.cn/index.php?ctl=designs&act=meiju_mobile_designs'
					},
					{
						name: "小院",
						pathName: "productDooryard",

					},
					{
						name: "Smart0",
						pathName: "smart0",

					},
					{
						name: "空中花园",
						pathName: "productDooryard",
					},
					{
						name: "小院在线",
						url: "http://mall.xiaoyuan.com.cn",
					},
					{
						name: "小院咖啡",
						pathName: "starbucks",
					},
					{
						name: "小院图书馆",
						pathName: "library",
					},
					{
						name: "小院健身",
						pathName: "fitness",
					},
					{
						name: "共享办公",
						pathName: "sharedOffice",
					},
					{
						name: "关于小院",
						pathName: "lifeBrand",
					},
					{
						name: "资讯中心",
						pathName: "newsList",
						params: '32,39,29,35,26'
					},
					{
						name: "在线商城",
						url: "http://mall.xiaoyuan.com.cn/",
					},
					{
						name: "企业合作",
						pathName: "lifeContact",
					},
					{
						name: "投诉建议",
						pathName: "feedback",
					},
					{
						name: "加入我们",
						url: "http://hr.xiaoyuan.com.cn",
					}
				],
			}
		},
		created() {
			if (this.$route.name != 'index') {
				var obj = {
					name: "首页",
					pathName: "index",
				}
				this.navList.unshift(obj)
			}
		},
		methods: {
			iconClick: function() {
				this.showNav = true;
			},
			navItemClick: function(e, i) {
				if (e.pathName) {
					this.$router.push({
						name: e.pathName,
						params: {
							paramsName: e.params
						}
					});
				}
				if (e.url) {
					window.location.href = e.url;
				}
				this.showNav = false;
			},
			memberClick: function() {
				this.$router.push({
					name: 'member'
				})
				this.showNav = false;
			}
		},
	}
</script>

<style lang="scss" scoped>
	.head-wrap {
		color: #393C41;
		position: fixed;
		z-index: 1000;
		right: 0;
		left: 0;
		top: 0px;

		.fun-area {
			height: 52px;
			padding: 0 26px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			&.navbgClass {
				background-image: url('../assets/images/new/navbg.png');
			}

			.logo {
				width: 87px;
			}

			.iconfont {
				color: #FFFFFF;
				font-size: 18px;
			}

			.icon-daohang {
				margin-left: 16px;

			}

			.iconguanbi {
				position: fixed;
				z-index: 999;
				top: 32px;
				right: 26px;
			}
		}

		.nav-list {
			padding: 22px 0 0 37px;
			box-sizing: border-box;

			.nav-item {
				font-size: 14px;
				line-height: 14px;
				margin: 29px 0;

				&:first-child {
					margin-top: 47px;
				}
			}
		}
	}
</style>