<template>
  <div class="contact-us-wrap">
    <div class="title-wrap">
      <div class="page">
        联系我们
      </div>
    </div>

    <div class="list-wrap">
      <div class="page">

        <div class="item-wrap" @click="service">
          <div class="iconfont icon-online"></div>
          <div class="name">在线支持</div>
        </div>

        <div class="item-wrap" @click="toolClick('lifeContact')">
          <div class="iconfont icon-phone1"></div>
          <div class="name">电话</div>
        </div>

        <div class="item-wrap" @click="weibo">
          <div class="iconfont icon-weibo2"></div>
          <div class="name">微博</div>
        </div>

        <div class="item-wrap weixin" @click="weixin" :class="showQrcode?'show-qrcode':''">

          <div class="flipper">
            <div class="front">
              <div class="iconfont icon-wechat"></div>
              <div class="name">微信</div>
            </div>
            <div class="back">
              <img src="../assets/images/qrcode.png" alt="">
            </div>
          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
import utils from '@/common/utils'

export default {
  name: 'ContactUs',
  data () {
    return {
      showQrcode: false,
    }
  },
  methods: {
    toolClick: utils.debounce(function (name) {
      this.$router.push({
        name
      })
    }, 500, true),
    service: utils.debounce(function () {
      window.open("http://chat.xiaoyuan.com.cn/index/index/home?visiter_id=&visiter_name=&avatar=&business_id=admin&groupid=0");
    }, 500, true),
    weibo: utils.debounce(function () {
      window.open("https://weibo.com/u/5686014371?is_hot=1");
    }, 500, true),
    weixin: utils.debounce(function () {
      this.showQrcode = !this.showQrcode
    }, 500, true),

  }
}
</script>

<style lang="scss" scoped>
.contact-us-wrap {
  width: 100%;
  .title-wrap {
    width: 100%;
    background-color: #f7f8f9;
    .page {
      width: 100%;
      margin: auto;
      font-size: 20px;
      height: 74px;
      line-height: 74px;
      text-align: center;
    }
  }
  .list-wrap {
    background-color: #fff;
    .page {
      width: 100%;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      padding: 30px;
      box-sizing: border-box;
      justify-content: space-around;
      .item-wrap {
        text-align: center;
        color: #707372;
        cursor: pointer;
        width: 120px;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 40px;
        &:hover {
          color: #00d596;
        }
        .iconfont {
          font-size: 76px;
          line-height: 76px;
        }
        .name {
          font-size: 15px;
          line-height: 15px;
          margin-top: 10px;
        }

        &.weixin {
          .flipper {
            width: 100%;
            height: 100%;
            text-align: center;
            transition:transform 0.5s;
            transform-style: preserve-3d;
            position: relative;
            .front,
            .back {
              width: 100%;
              height: 100%;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              backface-visibility: hidden; /*div背对屏幕时不可见*/
              position: absolute;
              top: 0;
              left: 0;
            }
            .back {
              transform: rotateY(180deg); /*反面内容初始化为背对着屏幕的*/
              >img{
                width: 100%;
              }
            }
          }

          &.show-qrcode{
            .flipper {
              transform: rotateY(180deg);
            }
          }
        }

      }
    }
  }
}
</style>