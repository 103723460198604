<template>
  <div>
    <Header :navbg='true'></Header>
    <div class="content">
      <div class="banner">
        <img src="../../../assets/images/project/online-experience-banner.jpg" alt="">
      </div>

      <div class="crumbs">
        <div class="page">
          首页 / 线上体验
        </div>
      </div>

      <div class="online-wrap">
        <div class="page">
          <div class="title">我们很高兴能够为您提供居住方案</div>
          <div class="desc">臻选100-200㎡ 匠造精筑典范</div>
          <div class="online-list">
            <div class="item" @click="vrClick('https://pano8.p.kujiale.com/cloud/design/3FO4BTVSTL3G/show')">
              <div class="iconfont icon-icon-test1"></div>
              <div class="name">小院怡居A户型</div>
            </div>
            <div class="item" @click="vrClick('https://pano8.p.kujiale.com/cloud/design/3FO4BJ7SGGHK/show')">
              <div class="iconfont icon-icon-test"></div>
              <div class="name">小院怡居B户型</div>
            </div>
            <div class="item" @click="vrClick('https://pano8.p.kujiale.com/design/3FO4BJ7R1EEL/show')">
              <div class="iconfont icon-icon-test2"></div>
              <div class="name">小院怡居C1户型</div>
            </div>
            <div class="item" @click="vrClick('https://pano8.p.kujiale.com/cloud/design/3FO4BJBP4WK6/show')">
              <div class="iconfont icon-icon-test3"></div>
              <div class="name">小院怡居C2户型</div>
            </div>
            <div class="item" @click="vrClick('https://pano8.p.kujiale.com/design/3FO4BOTUU778/show')">
              <div class="iconfont icon-A"></div>
              <div class="name">小院怡居D1户型</div>
            </div>
            <div class="item" @click="vrClick('https://pano8.p.kujiale.com/cloud/design/3FO4GDFY660G/show')">
              <div class="iconfont icon-B"></div>
              <div class="name">小院怡居D2户型</div>
            </div>
            <div class="item" @click="vrClick('https://pano8.p.kujiale.com/design/3FO4B92PYYSN/show')">
              <div class="iconfont icon-icon-test2"></div>
              <div class="name">小院怡居E1户型</div>
            </div>
            <div class="item" @click="vrClick('https://pano8.p.kujiale.com/design/3FO4B92IYGK8/show')">
              <div class="iconfont icon-icon-test3"></div>
              <div class="name">小院怡居E2户型</div>
            </div>
          </div>
        </div>
      </div>

      <ContactUs></ContactUs>

    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/head2022.vue'
import Footer from '@/components/foot2022.vue'
import ContactUs from '@/components/contact-us.vue'

export default {
  name:"onlineExperience",
  data () {
    return {

    }
  },
  components: {
    Header,
    Footer,
    ContactUs,
  },
  methods:{
    vrClick(url){
      window.open(url)
    },
  },
}
</script>

<style lang="scss" scoped>
.content{
  .banner{
    width: 100%;
    img{
      width: 100%;
    }
  }
  .crumbs{
    width: 100%;
    background-color: #F7F8F9;
    .page{
      width: 100%;
      padding: 26px 18px;
      box-sizing: border-box;
      font-size: 12px;
      color: #707473;
    }
  }
  .online-wrap{
    width: 100%;
    background-color: #fff;
    .page{
      width: 100%;
      padding: 38px 0 25px;
      .title{
        font-size: 16px;
        color: #707473;
        line-height: 16px;
        text-align: center;
      }
      .desc{
        font-size: 12px;
        color: #989898;
        line-height: 12px;
        text-align: center;
        margin-top: 30px;
      }
      .online-list{
        margin-top: 48px;
        display: flex;
        justify-content: space-around;
        width: 100%;
        box-sizing: border-box;
        flex-wrap: wrap;
        padding: 0 30px;
        .item{
          width: 118px;
          height: 118px;
          display: flex;
          flex-direction: column;
          border: 1px solid #707473;
          border-radius: 2px;
          justify-content: center;
          align-items: center;
          color: #707372;
          cursor: pointer;
          margin-bottom: 28px;
          .iconfont{
            font-size: 54px;
          }
          .name{
            font-size: 12px;
            margin-top: 12px;
          }
          &:active{
            background-color: #F7F8F9;
          }
        }
      }
    }
  }
}
</style>