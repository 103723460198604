<template>
  <div class="footer">
    <div class="nav-list">
    	<div class="nav-item"  v-for="(item,index) in navList" :key="index" @click="footerItemClick(item)">{{item.name}}</div>
    </div>
	<div class="nav-item">Copyright © 2022 西安小院科技股份有限公司 版权所有</div>
	<div class="nav-item"><a href="https://beian.miit.gov.cn/" target="_blank">陕ICP备18018083号</a></div>
	<div class="nav-item"><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61011302001720" target="_blank" class="ga-data"><img src="../assets/images/public-record.png"/>陕公网安备 61011302001720号</a></div>
	<!-- <div class="nav-item tips">本网站直接或直接向商业消费者销售商品或者的宣传均属于“广告”（推销及参数、售后保障商品信息除外）</div> -->
  </div>
</template>

<script>
import utils from '@/common/utils'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      navList: [
        {
          name: '关于小院',
          pathName: "lifeBrand"
        },
        {
          name: '资讯中心',
          pathName: "newsList",
		  params:'32,39,29,35,26'
        },
        {
          name: '活动中心',
          pathName: "feedback",
		  params:'39'	
        },
		{
		  name: '企业合作',
		  pathName: "lifeContact"
		},
		{
		  name: '在线客服',
		  service: "service"
		},
		{
		  name: '办公登录',
		  url: "http://oa.xiaoyuan.com.cn"
		},
      ]
    }
  },
  computed: {
    ...mapState(['customer_service']),
  },
  methods: {
    footerItemClick(e){
		if (e.pathName) {
			this.$router.push({
				name: e.pathName,
				params: {
					paramsName: e.params
				}
			});
		}
		if (e.service) {
			this.service();
		}
		if (e.url) {
			window.location.href = e.url;
		}
    },
    service () {
      if (this.customer_service) {
        window.open(this.customer_service.url);
      } else {
        window.open("http://chat.xiaoyuan.com.cn/index/index/home?visiter_id=&visiter_name=&avatar=&business_id=admin&groupid=0");
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  text-align: center;
  padding: 35px 0 15px;
  // box-shadow: 0px 0px 0px 3px rgba(221, 221, 221, 0.34);
  .nav-item{
	  font-size: 14px;
	  font-weight: 400;
	  color: #7D7D7F;
	  line-height: 14px;
	  margin-bottom: 10px;
	  
  }
  .tips{
	  line-height: 16px;
	  padding: 0 20px;
  }
}
</style>